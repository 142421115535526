import React from "react";
import { ChakraProvider, theme } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast";
import Preloader from "./components/Preloader";

const AllRoutes = React.lazy(() => import("./routes"));

function App() {
  const toastOpt = {
    style: {
      borderRadius: "50px",
      background: "rgba(0%, 0%, 0%, 0.75)",
      color: "#fff",
      opacity: 0.2,
      fontSize: "12px",
      padding: "10px 20px",
    },
    success: {
      color: "#fff",
      style: {
        // border: '1px solid #713200',
        color: "#fff",
        fontSize: ".8rem",
        borderRadius: "10px",
        background: "#333",
      },
      // iconTheme: {
      //   primary: '#fff',
      //   secondary: '#333',
      // },
    },
    error: {
      style: {
        color: "#Ff4141",
        border: "1px solid #Ff4141",
        // fontSize: ".8rem",
        background: "#FFF0F0",
      },
      iconTheme: {
        primary: "#Ff4141",
        secondary: "white",
      },
    },
  };

  return (
    <ChakraProvider theme={theme}>
      <React.Suspense fallback={<Preloader />}>
        <Toaster
          containerStyle={{
            zIndex: "10000000",
          }}
          toastOptions={toastOpt}
        />
        <AllRoutes />
      </React.Suspense>
    </ChakraProvider>
  );
}

export default App;
