import { Image } from "@chakra-ui/image";
import { Flex, VStack } from "@chakra-ui/layout";
import React from "react";
import { imageBaseUrl } from "../baseUrl";

let logo = `${imageBaseUrl}credo-img.png`;

const Loading = () => {
  return (
    <Flex h="100vh" w="100vw" alignItems="center" justify="center">
      <VStack gap="12px">
        <div>
          <Image src={logo} alt="logo" w="70px" className="grow" />{" "}
        </div>
        <div className="center">
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          {/* <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div> */}
          {/*<div className="wave"></div>
          <div className="wave"></div> */}
        </div>
      </VStack>
    </Flex>
  );
};

export default Loading;
